import React from "react"
import { Navbar, Container, Nav, Button, Text } from "react-bootstrap"
import {Link} from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";

import "./header.scss" 


export default function Header({activePage}) {
  return  (
  <>
    <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>DED Book - {activePage}</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
  <Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Brand href="#home">Data-enabled Design</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="container-fluid">
        <Link className={activePage=== "Home" ? "nav-link active" : "nav-link"} to="/">Home</Link>
        <Link className={activePage=== "Principles" ? "nav-link active" : "nav-link"} to="/principles">Principles</Link>
        <Link className={activePage=== "Courses" ? "nav-link active" : "nav-link"} to="/courses">Courses</Link>
        <Link className={activePage=== "Projects" ? "nav-link active" : "nav-link"} to="/projects">Projects</Link>
        <Link className={activePage=== "Authors" ? "nav-link active" : "nav-link"} to="/instructors">Authors</Link>
        {/* <Nav.Item className="ms-auto">
            <Button target="_blank" href="https://cvent.me/AgNgla">Sign up</Button>
        </Nav.Item> */}
        {/* <Link className={activePage=== "Materials" ? "nav-link active" : "nav-link"} to="/materials">Materials</Link> */}
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
</>)
}
